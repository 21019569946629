import React from "react"
import { useState } from "react"
import { Lightbox } from "react-modal-image"
import mpd2m from "../images/mpd2m_small.png"

import { TeplateStyled } from "../../../templates/productTemplate"

export default function Mpd010() {
  const [currentImageName, setCurrentImageName] = useState(null)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (name) => {
    setCurrentImageName(name)
    setViewerIsOpen(true)
  }

  function closeLightbox() {
    setViewerIsOpen(false)
    setCurrentImageName(null)
  }

  return (
    <TeplateStyled>
      <div className="MainNew">
        <div className="ProdWrapper">
          <h1 className="d-block">ТКР «ВОЛГА» ТИЖМ.466229.001</h1>
          <div
            className="ImgWrapperFlow float-left"
            style={{ marginLeft: -20 }}
            onClick={() => {
              openLightbox("mpd2m.png")
            }}
          >
            <img src={mpd2m} />
          </div>

          <p>
            Транспортный компьютер с радиоканалом с безвентиляторным
            охлаждением, выполненный на импортонезависимой компонентной базе.
          </p>

          <p>
            Двусторонняя передача данных по двум независимым защищенным
            радиоканалам РОРС GSM с использованием криптографической защиты
            информации VipNet Client.
          </p>
          <p>
            Организация двустороннего обмена с комплексом БЛОК (БЛОК-М, МПСУ-БД)
            через устройство безопасности БРСМ для безопасной передачи в
            подсистему КЛУБ бланка ДУ-61 и базы данных электронной карты, а
            также обратной передачи файлов поездки с соблюдением требований
            функциональной защиты. Используемый интерфейс - Ethernet.
          </p>
          <p>
            Организация двустороннего обмена с комплексом БЛОК (БЛОК-М, МПСУ-БД)
            через устройство безопасности БРСМ для безопасной передачи в
            подсистему САУТ базы данных электронной карты, а также обратной
            передачи файлов поездки с соблюдением требований функциональной
            защиты. Используемый интерфейс - Ethernet.
          </p>
          <p>
            Организация двустороннего обмена с микропроцессорной системой
            управления локомотива МПСУиД (в подсистему Автоведения и Систему
            информирования машиниста) для передачи бланка ДУ-61, расписания
            движения, данных ВСЦ и обратной передачи данных поездки с
            соблюдением требований функциональной защиты. Используемый интерфейс
            - CAN.
          </p>
          <p>
            Программное обеспечение блока внесено в реестр отечественного ПО.
          </p>
          <p>Операционная система на базе ядра Linux.</p>
          <p>
            Аппаратная часть выполнена на импортонезависимой
            электронно-компонентной базе (материковый Китай).
          </p>

          <table>
            <tbody>
              <tr>
                <td>Режим работы</td>
                <td>непрерывный, круглосуточный</td>
              </tr>
              <tr>
                <td>Диапазон питающего напряжения постоянного тока, В</td>
                <td>от 40 до 65</td>
              </tr>
              <tr>
                <td>Средний потребляемый ток, А</td>
                <td>0,3</td>
              </tr>
              <tr>
                <td>Импульсный потребляемый ток, не более, А</td>
                <td>1,5</td>
              </tr>
              <tr>
                <td>
                  Продолжительность бесперебойной работы при отсутствии внешнего
                  питания, не менее, сек
                </td>
                <td>60</td>
              </tr>
              <tr>
                <td>Волновое сопротивление нагрузки антенной цепи, Ом</td>
                <td>50</td>
              </tr>
              <tr>
                <td>
                  Управление терминированием интерфейсов CAN сопротивлением 120
                  Ом
                </td>
                <td>встроенным программным обеспечением</td>
              </tr>
              <tr>
                <td>Допустимый диапазон рабочих температур, ºС</td>
                <td>от минус 50 до плюс 60</td>
              </tr>
              <tr>
                <td>Максимальная скорость передачи данных:</td>
                <td></td>
              </tr>
              <tr>
                <td className="pl-6">– в режимах EDGE, UMTS, кбит/с</td>
                <td>384</td>
              </tr>
              <tr>
                <td className="pl-6">– в режиме HSPA, Мбит/с</td>
                <td>7,2</td>
              </tr>
              <tr>
                <td>Рабочие диапазоны частот, МГц</td>
                <td>от 850 до 900, от 1800 до 1900</td>
              </tr>
              <tr>
                <td>Режим передачи</td>
                <td>полудуплексный</td>
              </tr>
              <tr>
                <td colSpan="2">
                  Усреднённая точность получения навигационных параметров:
                </td>
              </tr>
              <tr>
                <td className="pl-6">– в плоскости, м</td>
                <td>2,5</td>
              </tr>
              <tr>
                <td className="pl-6">– высоты, м</td>
                <td>3</td>
              </tr>
              <tr>
                <td className="pl-6">– скорости, м/с</td>
                <td>0,05</td>
              </tr>
              <tr>
                <td className="pl-6">– времени, нс</td>
                <td>±25</td>
              </tr>
              <tr>
                <td>
                  Скорость обмена данными по интерфейсам CAN, RS-232, RS-485,
                  Ethernet
                </td>
                <td>регулируется встроенным программным обеспечением</td>
              </tr>
              <tr>
                <td>Количество модулей расширения*</td>
                <td>6</td>
              </tr>
              <tr>
                <td>Индикация режимов работы, разрешение</td>
                <td>OLED-дисплей, 128 x 64</td>
              </tr>
              <tr>
                <td>Модуль мобильной связи и навигации</td>
                <td></td>
              </tr>
              <tr>
                <td>Поддерживаемые стандарты связи</td>
                <td>
                  GSM 900 МГц, GSM 1800 МГц, WCDMA B8, LTE-FDD B1, LTE-FDD B3,
                  LTE-FDD B7, LTE-FDD B8, LTE-FDD B20, LTE CAT1, LTE CAT4
                </td>
              </tr>
              <tr>
                <td>Поддерживаемые стандарты навигации</td>
                <td>ГЛОНАСС, GPS, BD</td>
              </tr>
              <tr>
                <td>Количество сим-карт</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Габаритный формат сим-карт</td>
                <td>Micro-sim</td>
              </tr>
              <tr>
                <td>Модуль интерфейсов</td>
                <td>CAN</td>
              </tr>
              <tr>
                <td>Количество интерфейсов</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Скорость работы по интерфейсам</td>
                <td>регулируется встроенным программным обеспечением</td>
              </tr>
              <tr>
                <td>Управление терминаторами 120 Ом</td>
                <td>подключение встроенным программным обеспечением</td>
              </tr>
              <tr>
                <td colSpan="2">Модуль интерфейса Wi-Fi</td>
              </tr>
              <tr>
                <td>Поддерживаемые стандарты связи</td>
                <td>802.11b/g/n</td>
              </tr>
              <tr>
                <td>Скорость передачи данных, Мбит/с</td>
                <td>до 150</td>
              </tr>
              <tr>
                <td>Протоколы безопасности</td>
                <td>WEP, WPA, WPA2</td>
              </tr>
              <tr>
                <td>Модуль интерфейса Ethernet</td>
                <td></td>
              </tr>
              <tr>
                <td>Скорость обмена, Мбит/сек</td>
                <td>10/100</td>
              </tr>
              <tr>
                <td>Поддерживаемые стандарты</td>
                <td>IEEE 802.3 10Base-T/100Base-TX, IEEE 802.3 100BASE-FX</td>
              </tr>
              <tr>
                <td>Степень защиты у корпуса</td>
                <td>IP53</td>
              </tr>
              <tr>
                <td>Габаритные размеры (Д x Ш x В), не более, мм</td>
                <td>260 × 175 × 90</td>
              </tr>
              <tr>
                <td>Средний срок службы, не менее, лет</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Средняя наработка на отказ, не менее, ч</td>
                <td>30 000</td>
              </tr>
              <tr>
                <td>Среднее время восстановления, не более, c</td>
                <td>60</td>
              </tr>
              <tr>
                <td>Вес, не более, кг</td>
                <td>2,0</td>
              </tr>
            </tbody>
          </table>
          <p>
            * - количество и тип устанавливаемых модулей зависит от требований
            Заказчика
          </p>
        </div>
      </div>
      {viewerIsOpen &&
        (function () {
          return (
            <>
              <Lightbox
                large={require(`../images/${currentImageName}`)}
                onClose={closeLightbox}
                hideZoom={true}
                showRotate={true}
              />
            </>
          )
        })()}
    </TeplateStyled>
  )
}
